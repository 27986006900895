import React from 'react'
import Image from 'next/image'
import akiverseGameSignup from '../../../../../public/img/akiverseGameSignup.png'
import playStore from '../../../../../public/img/play_store.png'
import appStore from '../../../../../public/img/app_store.png'

export type Props = {}

export const QRCode: React.VFC<Props> = () => {
  return (
    <div className="pc-only">
      <div className="qrcode-view">
        <div className="qrcode-view-content">
          <div className="qrcode-view-title">
            <div>Play in Browser Ver</div>
          </div>
          <div className="qrcode-view-image">
            <Image src={akiverseGameSignup} alt="qr" height="128" width="128" />
          </div>
        </div>
        <div className="qrcode-view-content">
          <div className="qrcode-view-title">
            <div>Apps now available!</div>
          </div>
          <div className="qrcode-view-image">
            <a href="https://play.google.com/store/apps/details?id=io.akiverse.arcade">
              <Image src={playStore} alt="play store" width="170" />
            </a>
          </div>
          <div className="qrcode-view-image">
            <Image src={appStore} alt="app store" width="170" />
          </div>
        </div>
      </div>
    </div>
  )
}
