import React from 'react'
import { Wrapper } from '../../../parts/bases/Wrapper'
import { Header, Props as HeaderProps } from '../../../parts/commons/Header'
import { Footer } from '../../../parts/commons/Footer'
import { Main, Props as MainProps } from './Main'
import { Movie } from './Movie'
import { Communities } from './Communities'
import { AppealPoint } from './AppealPoint'
import { Token } from './Token'
import { QRCode } from './QRCode'

export type Props = {} & MainProps & HeaderProps

export const Layout: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <Header visibleLaunchAppButton={props.visibleLaunchAppButton} />
      <Main visibleCreateAccountButton={props.visibleCreateAccountButton} />
      <Movie />
      <Communities />
      <AppealPoint />
      <Token />
      <Footer />
      <div id="loadPolicy"></div>
      <QRCode />
    </Wrapper>
  )
}
