import React from 'react'
import { Layout } from './Layout'
import { Head } from '../../parts/commons/Head'
import { useProps } from './useProps'

export const LandingPage = () => {
  return (
    <>
      <Head title={'AKIVERSE'} />
      <Layout {...useProps()} />
    </>
  )
}
