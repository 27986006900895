import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import logo_white from '../../../../public/img/logo_white.png'
import icon_twitter from '../../../../public/img/icon_twitter.png'
import icon_discord from '../../../../public/img/icon_discord.png'
import icon_midium from '../../../../public/img/icon_midium.png'
import icon_telegram from '../../../../public/img/icon_telegram.png'

export const Footer: React.VFC = () => {
  return (
    <footer className="footer">
      <div className="footer__contents">
        <div className="footer__contents-logo">
          <div className="logo1">
            <Link href="/">
              <a>
                <div className="footer_logo_img">
                  <Image src={logo_white} alt="AKIVERSE" objectFit="contain" />
                </div>
              </a>
            </Link>
          </div>
        </div>
        <div className="footer__contents-link">
          <ul className="footer-link1">
            <li>
              <Link href="https://twitter.com/akiverse_io">
                <a>
                  <div className="footer_sns_logo_img">
                    <Image src={icon_twitter} alt="twitter" layout="fill" />
                  </div>
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://discord.gg/fanCVKWWFA">
                <a>
                  <div className="footer_sns_logo_img">
                    <Image src={icon_discord} alt="discord" layout="fill" />
                  </div>
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://medium.com/akiverse">
                <a>
                  <div className="footer_sns_logo_img">
                    <Image src={icon_midium} alt="medium" layout="fill" />
                  </div>
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://t.me/+kis4ZtRbANVhZWQ1">
                <a>
                  <div className="footer_sns_logo_img">
                    <Image src={icon_telegram} alt="telegram" layout="fill" />
                  </div>
                </a>
              </Link>
            </li>
          </ul>
          <ul className="footer-link2">
            <li>
              <Link href="/">
                <a>About</a>
              </Link>
            </li>
            <li>
              <Link href="/terms">
                <a>Terms &amp; Conditions</a>
              </Link>
            </li>
            <li>
              <Link href="/policy">
                <a>Privacy Policy</a>
              </Link>
            </li>
            <li>
              <Link href="/license">
                <a>Art License</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <p className="copy">Copyright 2023, Akiverse INC.</p>
    </footer>
  )
}
