import React from 'react'

export type Props = {}

export const AppealPoint: React.VFC<Props> = () => {
  return (
    <div>
      <section className="feature-game">
        <h1 className="title move">
          <span>Play-</span>and<span>-Earn</span> online
        </h1>
        <p className="text fadein">
          Play for Free and pocket all the <br className="sp-only" />
          winnings. Break highscores at
          <br className="sp-only" />
          multiple NFT <br className="pc-only" />
          Arcade Machines <br className="sp-only" />
          and win
          <span> AKIR</span> Tokens
        </p>
        <div className="container">
          <div className="next"></div>
          <div className="prev"></div>
          <div className="carousel">
            <div className="a">
              <video
                className="video item"
                poster="/mov/BubbleAttack.png"
                loop
                playsInline
                preload={'true'}
                autoPlay
                muted>
                <source src="/mov/BubbleAttack.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="b">
              <video
                className="video item"
                poster="/mov/StarGuardian.png"
                loop
                playsInline
                preload={'true'}
                autoPlay
                muted>
                <source src="/mov/StarGuardian.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="c">
              <video
                className="video item"
                poster="/mov/YummyJump.png"
                loop
                playsInline
                preload={'true'}
                autoPlay
                muted>
                <source src="/mov/YummyJump.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>
      <section className="feature1">
        <div className="feature">
          <div className="feature-txt">
            <h1 className="title move">
              More <span>Gameplay</span>
              <br />= Higher <span>revenue</span>
            </h1>
            <p className="text fadein">
              Own &amp; Manage NFT Arcade Machines to generate income. Earn a percentage from the player’s
              winnings.
            </p>
          </div>
          <div className="feature-img move">
            <div className="slider">
              <div className="slider-item">
                <video
                  poster="/mov/bubble_attack_arcade.png"
                  loop
                  playsInline
                  preload={'true'}
                  autoPlay
                  muted>
                  <source src="/mov/bubble_attack_arcade.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="slider-item">
                <video
                  poster="/mov/star_guardian_arcade.png"
                  loop
                  playsInline
                  preload={'true'}
                  autoPlay
                  muted>
                  <source src="/mov/star_guardian_arcade.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="slider-item">
                <video poster="/mov/yummy_jump_arcade.png" loop playsInline preload={'true'} autoPlay muted>
                  <source src="/mov/yummy_jump_arcade.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature2">
        <div className="feature">
          <div className="feature-txt">
            <h1 className="title move">
              Buy/Sell unique <span>Arcade Parts</span>
            </h1>
            <p className="text fadein">
              Craft top-performing Arcade Machines to earn more. Exchange tokens to Extract &amp; Dismantle
              Arcade Machine parts on the Marketplace.
            </p>
          </div>
          <div className="feature-img move">
            <div className="slider">
              <div className="slider-item">
                <video poster="/mov/ap_rom_purple.png" loop playsInline preload={'true'} autoPlay muted>
                  <source src="/mov/ap_rom_purple.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="slider-item">
                <video poster="/mov/ap_acc_hokuto100lx.png" loop playsInline preload={'true'} autoPlay muted>
                  <source src="/mov/ap_acc_hokuto100lx.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="slider-item">
                <video poster="/mov/ap_uc_plain.png" loop playsInline preload={'true'} autoPlay muted>
                  <source src="/mov/ap_uc_plain.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="slider-item">
                <video poster="/mov/ap_lc_plain.png" loop playsInline preload={'true'} autoPlay muted>
                  <source src="/mov/ap_lc_plain.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature3">
        <div className="feature">
          <div className="feature-txt">
            <h1 className="title move">
              Income for <span>Game Center</span> Owners
            </h1>
            <p className="text fadein">
              Choose from three sizes of Game Centers in various locations and supply the Arcade Machines of
              your choice.
            </p>
            <p className="link transaction-link fadein">
              <a href="https://tofunft.com/collection/akiverse-game-centers/items">Buy Now</a>
            </p>
          </div>
          <div className="feature-img move">
            <div className="slider">
              <div className="slider-item">
                <video poster="/mov/07_large_gamecenter.png" loop playsInline preload={'true'} autoPlay muted>
                  <source src="/mov/07_large_gamecenter.webm" type="video/webm" />
                  <source src="/mov/07_large_gamecenter.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="slider-item">
                <video
                  poster="/mov/08_medium_gamecenter.png"
                  loop
                  playsInline
                  preload={'true'}
                  autoPlay
                  muted>
                  <source src="/mov/08_medium_gamecenter.webm" type="video/webm" />
                  <source src="/mov/08_medium_gamecenter.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="slider-item">
                <video poster="/mov/09_small_gamecenter.png" loop playsInline preload={'true'} autoPlay muted>
                  <source src="/mov/09_small_gamecenter.webm" type="video/webm" />
                  <source src="/mov/09_small_gamecenter.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
