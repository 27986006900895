import React from 'react'
import ReactPlayer from 'react-player'

export type Props = {}

export const Movie: React.VFC<Props> = () => {
  return (
    <section className="movie fadein">
      <div className="movie-wrap">
        <div className="movie-wrap__box">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=N5CFCsXXQUM"
            loop
            volume={0}
            playing
            width={1200}
            height={675}
          />
        </div>
      </div>
    </section>
  )
}
