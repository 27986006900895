import React from 'react'
import Image from 'next/image'
import main_logo from '../../../../../public/img/main_logo.png'
import create_account from '../../../../../public/images/create_account.png'
import playStore from '../../../../../public/img/play_store.png'
import appStore from '../../../../../public/img/app_store.png'
import { HStack } from '../../../parts/bases/HStack'

export type Props = {
  visibleCreateAccountButton: boolean
}

export const Main: React.VFC<Props> = (props) => {
  return (
    <section className="main">
      <h2 className="main__logo countinfinite logoAnimation">
        <div className="main_logo_img">
          <Image src={main_logo} alt="AKIVERSE" objectFit="contain" />
        </div>
      </h2>
      <h3 className="title-sub">
        <span>
          SPARK THE WORLD <br className="sp-only" />
          WITH THE POWER OF GAMING!
        </span>
      </h3>
      <p>
        AKIVERSE is a world cloaked <br className="sp-only" />
        in darkness and is awaiting saviour.
        <br />
        Games and players are <br className="sp-only" />
        the &quot;only&quot; source of light.
        <br />
        Will you be the one to save AKIVERSE?
      </p>
      {props.visibleCreateAccountButton ? (
        <>
          <div className="pc-only">
            <p className="title-sub">
              <span>Open Beta</span>
              <a href="https://world-manager.akiverse.io/">
                <Image src={create_account} width={484} height={102} layout="fixed" alt="Create account" />
              </a>
            </p>
          </div>
          <div className="sp-only">
            <p className="appAvailable">
              <span>Apps now available</span>
            </p>
          </div>
          <div className="sp-only">
            <HStack className="storeBadge">
              <Image src={appStore} alt="app store" width="170" />
            </HStack>  
            <HStack className="storeBadge">
              <a href="https://play.google.com/store/apps/details?id=io.akiverse.arcade">
                <Image src={playStore} alt="play store" width="170" />
              </a>
            </HStack>
          </div>
          <div className="sp-only">
            <a className="play-game-button" href="https://game.akiverse.io/signup/">
              <div>Play in Browser Ver</div>
            </a>
          </div>
        </>
      ) : null}
      <div id="video-area">
        <video id="video" poster="/mov/banner.jpg" loop playsInline preload={'true'} autoPlay muted>
          <source src="/mov/banner.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  )
}
