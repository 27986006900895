import React from 'react'
import Image from 'next/image'
import logo_white from '../../../../public/img/logo_white.png'
import account from '../../../../public/images/account.png'

export type Props = {
  visibleLaunchAppButton: boolean
}

export const Header = (props: Props) => {
  return (
    <header className="header">
      <div className="header__contents">
        <div className="header__logo">
          <h1>
            <a href="../">
              <div className="header_logo_img">
                <Image src={logo_white} alt="AKIVERSE" objectFit="contain" />
              </div>
            </a>
          </h1>
        </div>
        <div className="header__nav nav__pc">
          <nav className="header__nav-menu">
            <ul>
              <li>
                <a href="../">About</a>
              </li>
              <li>
                Staking<span className="coming-soon">COMING SOON</span>
              </li>
              <li>
                <a href="https://tofunft.com/collection/akiverse-arcade-parts/items">Marketplace</a>
              </li>
              <li>
                <a href="https://whitepaper.akiverse.io/">Whitepaper</a>
              </li>
              <li>
                <a href="https://world-manager.akiverse.io/">World Manager</a>
              </li>
              <li>
                {props.visibleLaunchAppButton ? (
                  <a className="header__launch-button" href="https://world-manager.akiverse.io/">
                    <span>LaunchApp</span>
                  </a>
                ) : (
                  <a href="https://world-manager.akiverse.io/">
                    <Image src={account} width={50} height={50} alt="Login" />
                  </a>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="nav__sp">
        <div className="openbtn">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <nav id="g-nav">
          <div id="g-nav-list">
            <ul className="g-nav-list1">
              <li>
                <a href="../">About</a>
              </li>
              <li>
                Staking<span>COMING SOON</span>
              </li>
              <li>
                <a href="https://tofunft.com/collection/akiverse-arcade-parts/items">Marketplace</a>
              </li>
              <li>
                <a href="https://whitepaper.akiverse.io/">Whitepaper</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}
