import React from 'react'
import Image from 'next/image'
import akir from '../../../../../public/img/akir.png'
import akv from '../../../../../public/img/akv.png'
import teras from '../../../../../public/img/teras.png'

export type Props = {}

export const Token: React.VFC<Props> = () => {
  return (
    <section className="transaction">
      <h1 className="title move">
        Transactions that <br className="sp-only" />
        you can trust.
      </h1>
      <h2 className="title-sub fadein">
        SWIFT &amp; SECURE TRANSACTIONS <br className="sp-only" />
        BUILT ON THE <span>ETHEREUM</span> <br />
        <span>BLOCKCHAIN NETWORK </span>
        WITH <br className="sp-only" />
        ADDITIONAL SPEED OF THE <br />
        <span>POLYGON LAYER</span>
      </h2>
      <div className="transaction__contents">
        <div className="item move">
          <figure>
            <div className="token_img">
              <Image src={akv} alt="AKV" layout="fill" />
            </div>
          </figure>
          <h3 className="text">
            <span>AKV</span>
            <br />
            The Governance Token
          </h3>
          <p>
            This special token grants exclusive perks such as a portion of the total revenue generated by
            Akiverse.
          </p>
        </div>
        <div className="item move">
          <figure>
            <div className="token_img">
              <Image src={akir} alt="AKIR" layout="fill" />
            </div>
          </figure>
          <h3 className="text">
            <span>AKIR</span>
            <br />
            The Utility Token
          </h3>
          <p>
            Use it for crafting Arcade Machines, paying rent to Game Center Managers and buying Arcade Machine
            EXP Boosters.
          </p>
        </div>
        <div className="item move">
          <figure>
            <div className="token_img">
              <Image src={teras} alt="Teras" layout="fill" />
            </div>
          </figure>
          <h3 className="text">
            <span>Teras</span>
            <br />
            In-World Currency
          </h3>
          <p>
            Teras is an in-world currency that can be exchanged for AKIR, used to rent space for AMs in GC, or
            used for crafting.
            <br className="pc-only" />
            It was designed and introduced to lower the barrier to entry for creators and to allow more people
            to enjoy AKIVERSE.
          </p>
        </div>
      </div>
    </section>
  )
}
