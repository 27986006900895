import React from 'react'
import Image from 'next/image'
import icon_twitter from '../../../../../public/img/icon_twitter.png'
import icon_discord from '../../../../../public/img/icon_discord.png'
import icon_midium from '../../../../../public/img/icon_midium.png'
import icon_telegram from '../../../../../public/img/icon_telegram.png'
import main_logo from '../../../../../public/img/main_logo.png'

export type Props = {}

export const Communities: React.VFC<Props> = () => {
  return (
    <section className="info fadein">
      <h1 className="title move">
        Join the <span>Akiverse </span>
        <br className="sp-only" />
        community
      </h1>
      <div className="info__sns">
        <ul>
          <li>
            <a href="https://twitter.com/akiverse_io">
              <div className="communities_sns_logo_img">
                <Image src={icon_twitter} alt="twitter" layout="fill" />
              </div>
            </a>
          </li>
          <li>
            <a href="https://discord.gg/fanCVKWWFA">
              <div className="communities_sns_logo_img">
                <Image src={icon_discord} alt="discord" layout="fill" />
              </div>
            </a>
          </li>
          <li>
            <a href="https://medium.com/akiverse">
              <div className="communities_sns_logo_img">
                <Image src={icon_midium} alt="medium" layout="fill" />
              </div>
            </a>
          </li>
          <li>
            <a href="https://t.me/+kis4ZtRbANVhZWQ1">
              <div className="communities_sns_logo_img">
                <Image src={icon_telegram} alt="telegram" layout="fill" />
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div className="title fadein">
        <p>WAYS TO EARN IN</p>
        <h2 className="info__title-logo">
          <div className="communities_logo_img">
            <Image src={main_logo} alt="AKIVERSE" objectFit="contain" />
          </div>
        </h2>
      </div>
    </section>
  )
}
